@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,700&display=swap);
:root {
  --primary: #0747a6;
  --primary-light: #58acdd;
  --gray: #4c4c53;
  --light-gray: #666666;
  --very-light-gray: #f5f5f5;
  --gold: #fdbd29;
  --header-text-color: var(--light-gray);
  --graph: #d9e0eb;
}
a {
  text-decoration: none;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
p {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: #4c4c53;
  color: var(--gray);
}

h1 {
  font-size: 27px;
  font-weight: 600;
}
p {
  color: #666666;
  color: var(--light-gray);
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 2px;
}

img {
  vertical-align: middle;
}

b,
strong {
  font-weight: 600;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

button {
  outline: none;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.padding-under-header {
  padding-top: 80px;
}

.section-container {
  padding: 60px 10%;
}

.fade-in {
  -webkit-animation: fadeInAnimation 300ms ease-in;
          animation: fadeInAnimation 300ms ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}

@-webkit-keyframes fadeInAnimation {
  0% {
    -webkit-transform: translate(0, 30px);
            transform: translate(0, 30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fadeInAnimation {
  0% {
    -webkit-transform: translate(0, 30px);
            transform: translate(0, 30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 5px 10% 5px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}
.header-on-top {
  padding-top: 40px;
  box-shadow: none;
  background-color: transparent;
}
.header-container-light {
  background: rgba(0, 0, 0, 0.15);
  background: -webkit-gradient(linear, left top, left bottom, from(#0000), to(#0003));
  background: linear-gradient(to bottom, #0000, #0003);
}
.header-menu {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: center;
  color: var(--header-text-color);
}
.menu-item {
  position: relative;
  padding: 0 20px;
}
.menu-item-border {
  width: 0%;
  height: 2px;
  background: var(--primary);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  margin-top: 7px;
  margin-bottom: -14px;
}
.menu-item:hover > .menu-item-border {
  width: 100%;
}

.header-menu-light > .menu-item {
  color: white;
  opacity: 1;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.header-menu-light > .menu-item > .menu-item-border {
  background: white;
  opacity: 0.5;
}

.menu-item > a {
  text-align: center;
  font-size: 15px;
  color: inherit;
  text-decoration: none;
}

.header-menu-light > .active:before {
  background-color: white;
}

.active:before {
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  height: 2px;
  top: 25px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  left: 50%;
  background-color: var(--primary);
}

.sub-home-menu-container {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: 100%;
  padding-top: 30px;
  -webkit-animation-name: fadein;
          animation-name: fadein;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.sub-home-menu {
  width: 200px;
  padding: 15px;
  position: relative;
  cursor: default;
  z-index: 9;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  background: white;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sub-menu-item {
  cursor: pointer;
  color: var(--light-gray);
  font-weight: 400;
  padding: 15px 0;
  -webkit-transition: color 400ms;
  transition: color 400ms;
}

.sub-menu-item:hover {
  color: var(--primary);
}

.hamburger-piece {
  display: block;
  height: 2px;
  width: 17px;
  margin: 0 0 4px;
  background: var(--primary);
}

.logo {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  cursor: pointer;
}

@media screen and (max-width: 1080px) {
  .header-container {
    padding: 40px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-menu {
    position: absolute;
    right: 15px;
    top: 80px;
    background: white;
    z-index: 9;
    flex-direction: column;
    justify-content: space-around;
    height: 400px;
    width: 330px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    -webkit-animation: fadein 0.3s;
            animation: fadein 0.3s;
  }
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 1000000;
}
.loader-image {
  opacity: 0.5;
  width: 110px;
}

.services-section {
  background-color: var(--very-light-gray);
  padding: 140px 10%;
  position: relative;
  overflow: auto;
}
.services-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.services-title {
  text-align: center;
  margin-bottom: 70px;
}

.expertise-container {
  position: relative;
  /* display: flex; */
}

.expertise-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: var(--primary);
  margin-top: 100px;
  box-shadow: 0px 10px 20px 0px #00000044;
}

.expertise-left-title {
  color: white;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  max-width: 800px;
  text-align: justify;
  letter-spacing: 3px;
}

.services-left-text {
  color: white;
  font-weight: 300;
  font-size: 14px;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.services-right {
  padding: 50px;
  background-color: white;
}

.services-right-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.services-right-item-title {
  margin-bottom: 30px;
}
.services-right-item-text {
  font-size: 14px;
}
.services-right-item-border {
  width: 80%;
  height: 2px;
  background: var(--very-light-gray);
  margin: 20px 0;
}
.services-right-item-img {
  padding: 0 30px;
}

