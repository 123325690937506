@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,700&display=swap");

:root {
  --primary: #0747a6;
  --primary-light: #58acdd;
  --gray: #4c4c53;
  --light-gray: #666666;
  --very-light-gray: #f5f5f5;
  --gold: #fdbd29;
  --header-text-color: var(--light-gray);
  --graph: #d9e0eb;
}
a {
  text-decoration: none;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
p {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: var(--gray);
}

h1 {
  font-size: 27px;
  font-weight: 600;
}
p {
  color: var(--light-gray);
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 2px;
}

img {
  vertical-align: middle;
}

b,
strong {
  font-weight: 600;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

button {
  outline: none;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.padding-under-header {
  padding-top: 80px;
}

.section-container {
  padding: 60px 10%;
}

.fade-in {
  animation: fadeInAnimation 300ms ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnimation {
  0% {
    transform: translate(0, 30px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
