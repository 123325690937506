.services-section {
  background-color: var(--very-light-gray);
  padding: 140px 10%;
  position: relative;
  overflow: auto;
}
.services-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.services-title {
  text-align: center;
  margin-bottom: 70px;
}

.expertise-container {
  position: relative;
  /* display: flex; */
}

.expertise-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: var(--primary);
  margin-top: 100px;
  box-shadow: 0px 10px 20px 0px #00000044;
}

.expertise-left-title {
  color: white;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  max-width: 800px;
  text-align: justify;
  letter-spacing: 3px;
}

.services-left-text {
  color: white;
  font-weight: 300;
  font-size: 14px;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.services-right {
  padding: 50px;
  background-color: white;
}

.services-right-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.services-right-item-title {
  margin-bottom: 30px;
}
.services-right-item-text {
  font-size: 14px;
}
.services-right-item-border {
  width: 80%;
  height: 2px;
  background: var(--very-light-gray);
  margin: 20px 0;
}
.services-right-item-img {
  padding: 0 30px;
}
